/**
 * = Input groups
 */

 .input-group-text.is-invalid {
	border-color: #fa5252;
  
	&:focus {
	  border-bottom: initial;
	}
  }
  
  .input-group-text.disabled {
	background-color: #f5f8fb;
	opacity: 1;
  }
  
  .input-group {
	border-radius: $input-border-radius;
	transition: $transition-base;
  
	.form-control {
	  box-shadow: none;
  
	  &:not(:first-child) {
		border-left: 0;
		padding-left: 0;
	  }
	  &:not(:last-child) {
		padding-right: 0;
	  }
	}
  
	.form-control.is-invalid {
	  &:focus {
		box-shadow: none;
		border-color: #fa5252;
	  }
	}
  }
  
  
  
  .input-group-text {
	font-size: $font-size-sm;
	transition: $input-transition;
	// border-right: 0;
  }
  
  .input-group-prepend {
	margin-right: -2px;
  }
  
  // .focus class is applied dinamycally from theme.js
  
  .focused {
	.input-group-text {
	  color: $input-group-addon-focus-color;
	  background-color: $input-group-addon-focus-bg;
	}
  
	.input-group-shadow {
	  box-shadow: $shadow-input-focus;
	}
  }
  